import Axios from 'axios';

export interface Dict {
  [key: string]: string;
}

export class PintraRedirect {
  public constructor() {
    if (window.name == 'msOnline') {
      window.opener.location.reload();
      window.close();
    }
  }

  public toMsOnline(loginHint: string = '', pRedirectTo: string = location.href): void {
    const siteUrlOverride = !!(window as any).wpo365.siteUrl;
    const actionUrl = siteUrlOverride ? (window as any).wpo365.siteUrl : window.location.href;
    const qs = this.queryString(window.location.href);
    const glue = Object.keys(this.queryString(actionUrl)).length === 0 ? '?' : '&';

    // Redirect from qs (e.g. dual login) overrides possible redirect passed as argument
    const redirectTo = 'redirect_to' in qs ? decodeURIComponent(qs['redirect_to']) : pRedirectTo;

    // Handle iframe
    if (window.top !== window) {
      localStorage.setItem('wpo365PopupToggled', 'toggled');
      this.PopupCenter(`actionUrl${glue}action=openidredirect&redirect_to=${redirectTo}`, 'msOnline', 480, 640);
      return;
    }

    const form = document.createElement('form');
    form.method = 'POST';
    form.action = `${actionUrl}${glue}cb=${this.uuidv4()}`;
    const params: any = {
      action: 'openidredirect',
      login_hint: loginHint,
      redirect_to: redirectTo
    };
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = params[key];
        form.appendChild(hiddenField);
      }
    }
    document.getElementById('wpo365OpenIdRedirect').appendChild(form);
    form.submit();
  }

  private uuidv4 = (): string => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  private queryString = (url: string): Dict => {
    const qsStart = url.indexOf('?');
    if (qsStart == -1) {
      return {};
    }
    const qs = url.substr(qsStart + 1);
    const kvPairs = qs.split('&');
    const result: Dict = {};
    for (let i = 0; i < kvPairs.length; i++) {
      const kv = kvPairs[i].split('=');
      result[kv[0]] = kv.length == 2 ? kv[1] : '';
    }
    return result;
  };

  private PopupCenter = (url: string, title: string, w: number, h: number) => {
    const width = screen.availWidth;
    const height = screen.availHeight;

    if (w > width) w = width;
    if (h > height) h = height;

    const left = (width - w) / 2;
    const top = (height - h) / 2;
    const newWindow = window.open(
      url,
      title,
      'width=' +
        w +
        ',height=' +
        h +
        ',top=' +
        top +
        ',left=' +
        left +
        'toolbar=no,menubar=no,scrollbars=no,location=no,status=no'
    );

    // Puts focus on the newWindow
    if (newWindow && window.focus) newWindow.focus();
  };
}
